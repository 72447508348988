import { gql } from "graphql-tag";
import { ApolloClient } from "@apollo/client";
import GoalType from "types/GoalType";
import { pick } from "lodash";
import { transformCamelToSnake } from "lib/stringFunctions";

export const insertGoalQuery = gql`
  mutation InsertGoalQuery($goal: goals_insert_input!) {
    insertResponse: insert_goals_one(object: $goal) {
      ...GoalFragment
    }
  }
`;

interface InsertGoalQueryType {
  insertResponse: GoalType;
}

const insertGoalFields = [
  "description",
  "goal",
  "user_id",
  "metric",
  "start_date",
  "end_date",
  "privacy_setting_id",
  "archived",
  "conditions",
];

export const insertGoal = ({
  client,
  goal,
}: {
  client: ApolloClient<any>;
  goal: GoalType;
}): Promise<GoalType> => {
  return new Promise((resolve, reject) => {
    client
      .mutate<InsertGoalQueryType>({
        mutation: insertGoalQuery,
        variables: {
          goal: pick(transformCamelToSnake(goal), insertGoalFields),
        },
      })
      .then((result) => {
        resolve(result.data.insertResponse);
      })
      .catch((e) => {
        reject(e.message);
      });
  });
};
