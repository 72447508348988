import { BookGroupSizeType } from "components/BookGroup/types";
import classNames from "lib/classNames";
import { PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
  size: BookGroupSizeType;
}

export default function BookGroupHeaderWrapper({ size, children }: Props) {
  return (
    <div
      className={classNames(
        size === "lg" ? "" : "lg:mb-0",
        size === "md" ? "pr-4" : "",
        size === "sm" ? "" : "mb-4"
      )}
    >
      {children}
    </div>
  );
}
