"use client";

import BookGroup from "components/BookGroup";
import {
  BookGroupSizeType,
  BookGoalContextType,
} from "components/BookGroup/types";
import BookGoalHeader from "components/BookGroup/headers/BookGoalHeader";
import classNames from "lib/classNames";
import GoalType from "types/GoalType";
import { UserType } from "types";
import useGoal from "services/GoalService/useGoal";

function NoItem() {
  return false;
}

export default function BookGoalGroup({
  goal: initialGoal,
  size,
  user,
  context = {},
}: {
  goal: GoalType;
  size: BookGroupSizeType;
  context?: BookGoalContextType;
  user: UserType;
}) {
  const { goal, archive } = useGoal({ goal: initialGoal });
  return (
    <BookGroup
      className={classNames(
        "bg-gray-50 dark:bg-gray-900 rounded-lg p-4 border-2 border-gray-200 dark:border-gray-700",
        goal.archived ? "opacity-70" : ""
      )}
      size={size}
      books={[]}
      HeaderComponent={BookGoalHeader}
      ItemComponent={NoItem}
      context={{
        ...context,
        user,
        goal,
        archive,
      }}
    />
  );
}
