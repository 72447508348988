import Badge from "hardcover-ui/components/Badge";
import pluralize from "lib/pluralize";
import { dateDiffInDays } from "lib/dateUtils";
import GoalType from "types/GoalType";
import useMount from "hooks/useMount";
export default function GoalDaysLeftBadge({ goal }: { goal: GoalType }) {
  const startDate = new Date(goal.startDate);
  const endDate = new Date(goal.endDate);
  const totalTime = dateDiffInDays(startDate, endDate);
  const ellapsedTime = dateDiffInDays(startDate, new Date());
  const daysLeft = totalTime - ellapsedTime;
  const mounted = useMount();

  if (daysLeft > 0) {
    if (!mounted) {
      <Badge size="sm" variant="secondary" className="hidden md:inline">
        days remaining
      </Badge>;
    } else {
      return (
        <Badge size="sm" variant="secondary" className="hidden md:inline">
          {daysLeft.toLocaleString()} {pluralize("day", daysLeft)} remaining
        </Badge>
      );
    }
  }

  return false;
}
