import Heading from "hardcover-ui/components/Heading";
import Text from "hardcover-ui/components/Text";
import TextLink from "hardcover-ui/components/TextLink";
import Badge from "hardcover-ui/components/Badge";
import pluralize from "lib/pluralize";
import Icon from "hardcover-ui/components/Icon";
import faCalendar from "icons/solid/calendar.svg";
import moment from "moment";
import { dateDiffInDays, isInPast } from "lib/dateUtils";
import classNames from "lib/classNames";
import { BookGroupSizeType, BookGoalContextType } from "../types";
import BookGroupHeaderWrapper from "./parts/BookGroupHeaderWrapper";
import ArchiveGoalButton from "features/goals/components/ArchiveGoalButton";
import GoalDaysLeftBadge from "features/goals/components/GoalDaysLeftBadge";

export default function BookGoalHeader({
  context,
  size,
}: {
  context: BookGoalContextType;
  size: BookGroupSizeType;
}) {
  const { user, goal } = context;

  if (!goal) {
    return false;
  }

  const startDate = new Date(goal.startDate);
  const endDate = new Date(goal.endDate);
  const totalTime = dateDiffInDays(startDate, endDate);
  const ellapsedTime = dateDiffInDays(startDate, new Date());

  const yearlyProgressTemp =
    100 - ((totalTime - ellapsedTime) / totalTime) * 100;
  const yearlyProgress = yearlyProgressTemp > 100 ? 100 : yearlyProgressTemp;

  const goalCount =
    goal.metric === "page" ? `${Math.round(goal.goal / 1000)}k` : goal.goal;

  let calculatedProgress = Math.floor((goal.progress / goal.goal) * 100);
  if (calculatedProgress > 100) {
    calculatedProgress = 100;
  }
  const progress = calculatedProgress || 0;

  // On track
  const isOnTrack = calculatedProgress >= yearlyProgress;
  const onTrack = isOnTrack;
  const pastDue = !isOnTrack && isInPast(endDate);

  // Progress Message
  const expectedProgress = Math.round((yearlyProgress / 100) * goal.goal);
  const differenceProgress = goal.progress - expectedProgress;
  const differenceProgressMessage =
    goal.metric === "page"
      ? `${Math.round(Math.abs(differenceProgress) / 1000)}k`
      : Math.abs(differenceProgress);

  let scheduleMessage;
  if (differenceProgress * (isOnTrack ? 1 : -1) < 1) {
    scheduleMessage = "You're right on schedule! 🙌";
  } else if (isOnTrack) {
    scheduleMessage = `You're <b>${differenceProgressMessage} ${pluralize(
      goal.metric,
      differenceProgress
    )} ahead of</b> schedule. 🙌`;
  } else if (pastDue) {
    scheduleMessage = `You were <b>${differenceProgressMessage} ${pluralize(
      goal.metric,
      differenceProgress * -1
    )} away</b> from reaching your goals!`;
  } else {
    scheduleMessage = `You're <b>${differenceProgressMessage} ${pluralize(
      goal.metric,
      differenceProgress * -1
    )} behind</b> schedule.`;
  }

  const progressMessage = scheduleMessage;

  return (
    <BookGroupHeaderWrapper size={size}>
      <div className="flex flex-row space-x-2 mb-1 justify-between">
        <div className="flex flex-row space-x-2 items-center">
          <Text variant="secondary" as="p" size="sm" className="font-semibold">
            Goal
          </Text>

          <Badge size="sm" variant={isOnTrack ? "success" : "secondary"}>
            {goal.progress.toLocaleString()}/{goal.goal.toLocaleString()}{" "}
            {pluralize(goal.metric, goal.goal)}
          </Badge>

          <GoalDaysLeftBadge goal={goal} />

          {goal.archived ? (
            <Badge size="sm" variant="warning">
              Archived
            </Badge>
          ) : (
            false
          )}
        </div>

        <div className="space-x-4 flex items-center">
          <ArchiveGoalButton goal={goal} />
          <Badge size="lg" variant={isOnTrack ? "success" : "primary"}>
            {Math.round(progress).toLocaleString()}%
          </Badge>
        </div>
      </div>

      <Heading level={3} size={size === "lg" ? "md" : "sm"} className="mb-2">
        <TextLink
          href={`/@${user.username}/goals/${goal.id}`}
          underline={false}
        >
          {goal.description}
        </TextLink>
      </Heading>

      {size === "lg" && (
        <Text variant="secondary" as="p">
          Read {goal.goal.toLocaleString()} {pluralize(goal.metric, goal.goal)}{" "}
          by {moment(goal.endDate).format("MMM D, YYYY")}.{" "}
          <span dangerouslySetInnerHTML={{ __html: progressMessage }} />
        </Text>
      )}

      <div className="relative my-6 w-full h-3 rounded-full bg-gray-300 dark:bg-gray-600">
        <div
          style={{
            width: `${progress}%`,
          }}
          className="absolute top-0 left-0 h-3 bg-yellow-400 z-10 rounded-full"
        />
        <div className="relative" style={{ marginRight: "25px" }}>
          <span
            className={classNames(
              "bg-yellow-400 rounded-lg absolute -ml-2 -mt-2 shadow-lg p-2 z-10 -top-2",
              onTrack
                ? "bg-yellow-100 text-yellow-500"
                : "bg-gray-600 text-gray-200 dark:text-gray-300"
            )}
            style={{
              left: `${progress}%`,
            }}
            data-tooltip-id="tooltip"
            data-tooltip-html={`<b>${goal.progress.toLocaleString()}</b> out of <b>${goalCount}</b> ${
              goal.metric
            }${goal.goal > 1 ? "s" : ""} read.<br/>${progressMessage}`}
            data-html
          >
            <Icon icon={faCalendar} size="lg" className="fill-yellow-900 " />
          </span>
        </div>
      </div>
    </BookGroupHeaderWrapper>
  );
}
