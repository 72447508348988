import { gql } from "graphql-tag";
import { ApolloClient } from "@apollo/client";
import GoalType from "types/GoalType";

export const updateProgressQuery = gql`
  mutation UpdateGoalProgress($goalId: Int!) {
    updateResponse: update_goal_progress(id: $goalId) {
      goal {
        ...GoalFragment
      }
    }
  }
`;

interface UpdateProgressQueryQueryType {
  updateResponse: {
    goal: GoalType;
  };
}

export const updateGoalProgress = ({
  client,
  goalId,
}: {
  client: ApolloClient<any>;
  goalId: number;
}): Promise<GoalType> => {
  return new Promise((resolve, reject) => {
    client
      .mutate<UpdateProgressQueryQueryType>({
        mutation: updateProgressQuery,
        variables: { goalId },
      })
      .then((result) => {
        resolve(result.data.updateResponse.goal);
      })
      .catch((e) => {
        reject(e.message);
      });
  });
};
