import { gql } from "graphql-tag";
import { ApolloClient } from "@apollo/client";
import GoalType from "types/GoalType";
import { pick } from "lodash";
import { transformCamelToSnake } from "lib/stringFunctions";

export const updateGoalQuery = gql`
  mutation UpdateGoalQuery(
    $pk: goals_pk_columns_input!
    $goal: goals_set_input!
  ) {
    updateResponse: update_goals_by_pk(pk_columns: $pk, _set: $goal) {
      ...GoalFragment
    }
  }
`;

interface UpdateGoalQueryType {
  updateResponse: GoalType;
}

const updateGoalFields = [
  "description",
  "goal",
  "metric",
  "start_date",
  "end_date",
  "privacy_setting_id",
  "archived",
  "conditions",
];

export const updateGoal = ({
  client,
  goal,
}: {
  client: ApolloClient<any>;
  goal: GoalType;
}): Promise<GoalType> => {
  return new Promise((resolve, reject) => {
    client
      .mutate<UpdateGoalQueryType>({
        mutation: updateGoalQuery,
        variables: {
          pk: { id: goal.id },
          goal: pick(transformCamelToSnake(goal), updateGoalFields),
        },
      })
      .then((result) => {
        resolve(result.data.updateResponse);
      })
      .catch((e) => {
        reject(e.message);
      });
  });
};
