"use client";

import { getClient } from "lib/apollo/client";
import GoalType from "types/GoalType";
import { insertGoal as insertGoalService } from "./actions/insertGoal";
import { updateGoalProgress as updateGoalProgressService } from "./actions/updateGoalProgress";
import { updateGoal as updateGoalService } from "./actions/updateGoal";

// INSERT
export async function insertGoal(goal: GoalType) {
  const client = await getClient();
  return insertGoalService({ client, goal });
}

// UPDATE GOAL PROGRESS
export async function updateGoalProgress(goalId: number) {
  const client = await getClient();
  return updateGoalProgressService({ client, goalId });
}

// UPDATE GOAL
export async function updateGoal(goal: GoalType) {
  const client = await getClient();
  return updateGoalService({ client, goal });
}
