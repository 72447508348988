"use client";

import Button from "hardcover-ui/components/Button";
import Icon from "hardcover-ui/components/Icon";
import useMount from "hooks/useMount";
import faArchive from "icons/solid/recycle.svg";
import { isInPast } from "lib/dateUtils";
import { useSelector } from "react-redux";
import useGoal from "services/GoalService/useGoal";
import { toast } from "sonner";
import { getUserId } from "store/currentUser/currentUserSelector";
import GoalType from "types/GoalType";

export default function ArchiveGoalButton({ goal }: { goal: GoalType }) {
  const { archive } = useGoal({ goal });
  const currentUserId = useSelector(getUserId);
  const pastDue = isInPast(goal.endDate);
  const mounted = useMount();

  // Only show this button if:
  //   Not archived
  //   Current user is the owner
  //   Past due
  if (
    !mounted ||
    goal.archived ||
    !archive ||
    currentUserId !== goal.userId ||
    !pastDue
  ) {
    return false;
  }

  const archiveGoal = () => {
    archive().then((newGoal) => {
      if (newGoal?.id) {
        toast.success("Goal archived!");
      }
    });
  };

  return (
    <Button variant="tertiary" size="sm" onClick={archiveGoal}>
      <Icon icon={faArchive} size="lg" className="fill-white" /> Archive
    </Button>
  );
}
