import classNames from "lib/classNames";
import BookGroupBooks from "./books/BookGroupBooks";
import DefaultHeaderComponent from "./headers/DefaultHeader";
import DefaultItem from "./items/DefaultItem";
import { BookGroupProps } from "./types";

export default function BookGroup({
  size,
  books,
  context,
  HeaderComponent = DefaultHeaderComponent,
  ItemComponent = DefaultItem,
  className = "",
}: BookGroupProps) {
  return (
    <div
      className={classNames(
        className,
        size === "lg" ? "flex flex-col" : "",
        size === "md" ? "grid grid-cols-1 lg:grid-cols-2 justify-between" : "",
        size === "sm" ? "flex flex-col gap-4" : ""
      )}
    >
      <HeaderComponent books={books} context={context} size={size} />
      <BookGroupBooks
        size={size}
        books={books}
        context={context}
        ItemComponent={ItemComponent}
      />
    </div>
  );
}
