import { BookType } from "types";
import classNames from "lib/classNames";
import DefaultItem from "../items/DefaultItem";
import { BookGroupSizeType, BookGroupContextType } from "../types";

export default function BookGroupBooks({
  size,
  books,
  context = {},
  ItemComponent = DefaultItem,
}: {
  size: BookGroupSizeType;
  books: BookType[];
  context?: BookGroupContextType;
  ItemComponent?: any;
}) {
  return (
    <div
      className={classNames(
        size === "sm" ? "flex flex-row gap-2 overflow-hidden" : "",
        size === "md" ? "flex flex-row gap-2 overflow-hidden" : "",
        size === "lg" ? "flex flex-row flex-wrap gap-5 lg:gap-2" : ""
      )}
    >
      {books.map((book, index) => (
        <ItemComponent
          size={size}
          context={context}
          book={book}
          key={`book-group-${book.slug}`}
          index={index + 1}
        />
      ))}
    </div>
  );
}
