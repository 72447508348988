import Text from "hardcover-ui/components/Text";
import pluralize from "lib/pluralize";
import { BookType } from "types";
import Badge from "hardcover-ui/components/Badge";
import BookGroupHeaderWrapper from "./parts/BookGroupHeaderWrapper";
import { BookGroupSizeType } from "../types";

export default function DefaultHeaderComponent({
  books,
  size,
}: {
  books: BookType[];
  size: BookGroupSizeType;
}) {
  return (
    <BookGroupHeaderWrapper size={size}>
      <div className="flex flex-row space-x-2 mb-1">
        <Text variant="secondary" as="p" size="sm" className="font-semibold">
          Books
        </Text>
        <Badge size="sm" variant="secondary">
          {books.length.toLocaleString()} {pluralize("book", books.length)}
        </Badge>
      </div>
    </BookGroupHeaderWrapper>
  );
}
