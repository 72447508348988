import { BookType } from "types";
import classNames from "lib/classNames";
import CoverLink from "components/Cover/CoverLink";
import { BookGroupSizeType, DefaultContextType } from "../types";

export default function DefaultItem({
  book,
  context,
  index,
  size,
}: {
  book: BookType;
  context: DefaultContextType;
  index: number;
  size: BookGroupSizeType;
}) {
  const active = context.activeBook?.id === book.id;

  return (
    <div className={classNames(index > 6 ? "hidden lg:block" : "")}>
      <div className="relative group">
        <CoverLink
          book={book}
          size={size === "lg" ? "md" : "sm"}
          variant={active ? "accent" : "none"}
          settings={{
            tooltip: true,
            showStatus: true,
            details: true,
          }}
        />
      </div>
    </div>
  );
}
