"use client";

import { useFragment } from "@apollo/experimental-nextjs-app-support/ssr";
import useMount from "hooks/useMount";
import GoalType from "types/GoalType";
import GoalFragmentCompiled from "queries/goals/fragments/GoalFragmentCompiled";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { getUserId } from "store/currentUser/currentUserSelector";
import { toast } from "sonner";
import { updateGoal } from "./GoalServiceClient";

interface Props {
  goal: GoalType;
}

const catchErrorMessage = (error: string) => {
  toast.error(`Something went wrong: ${error}`);
  return error;
};

export default function useGoal({ goal: initialGoal }: Props) {
  const mounted = useMount();
  const currentUserId = useSelector(getUserId);
  const { data: goal } = useFragment({
    fragment: GoalFragmentCompiled,
    fragmentName: "GoalFragment",
    from: {
      __typename: "goals",
      id: initialGoal?.id,
    },
  });

  const archive = useCallback(() => {
    // Noop unless the owner
    if (goal?.userId !== currentUserId) {
      return Promise.resolve(goal);
    }

    return updateGoal({
      ...goal,
      ...{ archived: true },
    }).catch(catchErrorMessage);
  }, [goal]);

  return {
    archive,
    goal: mounted ? (goal?.id ? goal : null) : initialGoal,
  };
}
